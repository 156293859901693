import {
  IChoiceGroupOption,
  IComboBoxOption,
  IDropdownOption,
} from '@fluentui/react';

type TextFieldItem = {
  textValue: string;
};

type SkillItem = {
  categoryValue: string;
  typeValue: string;
  textValue: string;
  sliderValue: number;
};

interface CheckboxOption {
  key: string;
  text: string;
}

//------------------------------------------------------------------------------------------------------------
// SEARCH COMPONENT
//------------------------------------------------------------------------------------------------------------

// Options and settings
//------------------------------------------------------------------------------------------------------------
/* Saved search settings */ //(ToDo: Getting saved settings functionality has to be implemented)
export const savedSearchSettings: IComboBoxOption[] = [
  { key: 'A', text: 'KI gestützte Ausschreibungsprofil-Suche' },
  { key: 'B', text: 'CV Keyword Suche' },
  { key: 'C', text: 'Tender/New Projects Mail Suche' },
];

/* Data source options */
export const dataSourceOptions: IDropdownOption[] = [
  { key: '0', text: 'Basic CV data' },
  { key: '1', text: 'Tender CVs' },
  { key: '2', text: 'Diamonds' },
];

/* Data source options */
export const dataSourceSimpleOptions: IDropdownOption[] = [
  { key: '0', text: 'Basic CV data:' },
  { key: '1', text: 'Tender CVs:' },
];

/* Search type options */
export const searchTypeOptions: IChoiceGroupOption[] = [
  { key: '0', text: 'Simple' }, // Search type = Full Text Search was changed to Simple
  //  { key: '1', text: 'Hybrid Search without KWE' },
  { key: '1', text: 'Smart' }, // Search type = Hybrid Search with KWE was changed to Smart
];

/* Search mode options */
export const searchModeOptions: IChoiceGroupOption[] = [
  { key: '0', text: 'Any' },
  { key: '1', text: 'All' },
];

/* Query type options */
export const queryTypeOptions: IChoiceGroupOption[] = [
  // { key: '0', text: 'Simple' },
  { key: '0', text: 'Standard' }, // Query type = Full was changed to Standard
  { key: '1', text: 'Semantic', disabled: false },
];

type StringMapping = {
  [key: string]: string;
};

// Example usage
export const queryTypeOptionsMapping: StringMapping = {
  "Standard": "Full",
  "Semantic": "Semantic",
};


/* Staffing status dropdowns */
export const staffingStatusOptions: IDropdownOption[] = [
  { key: 'presumablyAvailable', text: 'presumably available' },
  { key: 'availableConfirmed', text: 'available confirmed' },
  { key: 'offered', text: 'offered' },
  { key: 'vv', text: 'VV' },
  { key: 'pvInProgress', text: 'PV in progress' },
  { key: 'unknown', text: 'unknown' },
  { key: 'noStaffingStatus', text: 'No staffing status' },
];

/* Staffing priority options */
export const staffingPriorityOptions: CheckboxOption[] = [
  { key: 'grün', text: 'green' },
  { key: 'gelb', text: 'yellow' },
  { key: 'rot', text: 'red' },
];

/* Org unit options */
export const orgUnitOptions: IDropdownOption[] = [
  { key: 'cuAAI', text: 'CU Applied AI' },
  { key: 'cuBEGIN', text: 'CU Buildings, Energy & Grid Infrastructure' },
  { key: 'cuBEGINH', text: 'CU Buildings, Energy & Grid Infrastructure Head-Unit' },
  { key: 'cuBCB', text: 'CU Business Control Banking' },
  { key: 'cuBCC', text: 'CU Business Control Corporates' },
  { key: 'cuBCI', text: 'CU Business Control Insurance & Asset Manager' },
  { key: 'cuCR', text: 'CU Credit Risk' },
  { key: 'cuDCCF', text: 'CU Digital Compliance & Corporate Finance' },
  { key: 'cuGRIT', text: 'CU grIT' },
  { key: 'cuMNR', text: 'CU Market & Non-Financial Risk' },
  { key: 'cuMNO', text: 'CU Markets & Operations' },
  { key: 'cuSEB', text: 'CU Software Engineering & BDA' },
  { key: 'cuTS', text: 'CU Trading Systems' },
  { key: 'cuVAL', text: 'CU Valuation' },
  { key: 'inCHEM', text: 'INTINC Chemistry' },
  { key: 'inDIS', text: 'INTINC d-fine Industrial Solutions' },
  { key: 'inMO', text: 'INTINC Mathematical Optimization' },
  { key: 'inMT', text: 'INTINC Mobility & Transportation' },
  { key: 'inPR', text: 'INTINC Pricing & Revenue Management' },
  { key: 'inRC', text: 'INTINC Retail & Consumer Products' },
  { key: 'management', text: 'Management' },
  { key: 'mainline', text: 'Mainline' },
  { key: 'nmoABC', text: 'NMO Applied Blockchain' },
  { key: 'nmoITGS', text: 'NMO IT Governance & Security' },
  { key: 'nmoPH', text: 'NMO Pharma & Healthcare' },
  { key: 'nmoPS', text: 'NMO Public Sector' },
];

/* Career level options */
export const careerLevelOptions: IDropdownOption[] = [
  { key: 'solutionEngineer', text: 'Solution Engineer' },
  { key: 'seniorSolutionEngineer', text: 'Senior Solution Engineer' },
  { key: 'consultant', text: 'Consultant' },
  { key: 'seniorConsultant', text: 'Senior Consultant' },
  { key: 'manager', text: 'Manager' },
  { key: 'seniorManager', text: 'Senior Manager' },
  { key: 'principal', text: 'Principal' },
  { key: 'principalExpert', text: 'Principal Expert' },
  { key: 'partner', text: 'Partner' },
];

/* Career track options */
export const careerTrackOptions: IDropdownOption[] = [
  { key: 'yellow', text: 'Yellow' },
  { key: 'orange', text: 'Orange' },
  { key: 'orangeFlex', text: 'Orange Flex' },
  { key: 'blue', text: 'Blue' },
];

/* Skill search slider settings */
export const maximumSkillSliderLevel = 3; // Maximum level for skills (starting from 0)
export const sliderSkillLevels = ['Low', 'Medium', 'Good', 'Very good']; // Number to string skill mapping (items must coincide with maximumSkillSliderLevel + 1)

// Default states of search settings
//------------------------------------------------------------------------------------------------------------
export const defaultSavedSearchSetting = { key: '', text: '' };
export const defaultDataSource: IDropdownOption[] = [
  { key: '0', text: 'Basic CV data' },
  { key: '1', text: 'Tender CVs' },
  { key: '2', text: 'Diamonds' },
];
export const defaultDataSoruceSimple: IDropdownOption[] = [
  { key: '0', text: 'Basic CV data' },
  { key: '1', text: 'Tender CVs' },
];
export const defaultSelectedSearchTypeOption = 0;
export const defaultToggleRag = false;
export const defaultSelectedSearchModeOption = 0;
export const defaultSelectedQueryTypeOption = 0;
export const defaultMaxResults = 100;
export const defaultSelectedAvDateMin = new Date(1999, 0, 1);
export const defaultSelectedAvDateMax = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
export const timeZoneOffsetHours = (-1) * defaultSelectedAvDateMin.getTimezoneOffset() / 60;
defaultSelectedAvDateMin.setHours(defaultSelectedAvDateMin.getHours() + timeZoneOffsetHours);
defaultSelectedAvDateMax.setHours(defaultSelectedAvDateMax.getHours() + timeZoneOffsetHours);

export const defaultCheckedStateStaffingStatus: IDropdownOption[] = []
export const defaultCheckedStateStaffingPriority: { [key: string]: boolean } = {
  grün: false,
  gelb: false,
  rot: false,
};
export const defaultSelectedOrgUnit: IDropdownOption[] = [
  { key: 'mainline', text: 'Mainline' },
];

export const defaultCheckedStateCareerLevel: IDropdownOption[] = [];
export const defaultCheckedStateCareerTrack: IDropdownOption[] = [];
export const defaultProjectPostalCode = '';
export const defaultProjectLocation = '';
export const defaultProjectMaxDistance = 100;
export const defaultProjectStartDate = new Date();
defaultProjectStartDate.setHours(defaultProjectStartDate.getHours() + timeZoneOffsetHours);
export const defaultProjectEndDate = new Date(2059, 11, 31);
defaultProjectEndDate.setHours(defaultProjectEndDate.getHours() + timeZoneOffsetHours);
export const defaultProjectAbsenceThreshold = 100;
export const defaultEntryDateAfter = new Date(1999, 0, 1);
defaultEntryDateAfter.setHours(defaultEntryDateAfter.getHours() + timeZoneOffsetHours);
export const defaultLastModifiedDateOlder = new Date();
defaultLastModifiedDateOlder.setHours(defaultLastModifiedDateOlder.getHours() + timeZoneOffsetHours);
export const defaultIndustryItems: TextFieldItem[] = [];
export const defaultExpertiseItems: TextFieldItem[] = [];
export const defaultSkillItems: SkillItem[] = [];
export const defaultSearchQuery = '';

//------------------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------------